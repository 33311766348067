import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { batch, useDispatch, useSelector } from 'react-redux';
import { isInclusivelyBeforeDay, SingleDatePicker } from 'react-dates';
import { useParams } from 'react-router-dom';
import {
  Col, Fade, Form, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import {
  cancelSub,
  fetchPaymentHistory,
  fetchProSub,
  manualSub,
  stopSub,
} from '../../../actions/userCompanyManagement/proManagement';
import { getErrorsStore, getProManagementStore, getUserStore } from '../../../selectors';
import FormActionButtons from '../../FormComponents/ActionButtons';
import FormTitleSection, { FormSubTitle } from '../../FormComponents/TitleSection/index';
import UniversalControl from '../../FormComponents/UniversalControl';
import { StyledLinkButton as LinkButton, TabContainer } from '../../StyledComponents';
import { DateRangePickerWrapper } from '../../DatesPicker';
import BillingTable from './BillingTable';
import ModalLoader from '../../ModalLoader';
import Loader from '../../Loader';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { color } from '../../../constants';
import {
  proPaymentMethods as payMethods,
  proSubscriptionStatus,
  supportedCurrencies,
  supportEmail,
} from '../../../constants/index';
import validators from './validators';
import { getNameById } from '../../../utils/constantsHandlers';
import match from '../../../utils/match';

const PlanTitle = styled.h4`
  margin-top: 25px;
  margin-bottom: 10px;
  margin-right: 70px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  max-width: 420px;
`;
const PlanInfo = styled.span`
  color: ${color.blue};
  font-weight: 700;
  padding-left: 12px;
  text-align: right;
`;
const InDebtIcon = styled.div`
  display: inline-block;
  font-weight: 400;
  margin: 0 0 10px 15px;
  padding: 3px 5px;
  font-size: 14px;
  border: 1px solid ${color.red};
  color: ${color.red};
  border-radius: 5px;
`;
const paymentTypeOptions = [
  ['daily', 'Daily', moment.utc().add(1, 'day')],
  ['weekly', 'Weekly', moment.utc().add(1, 'week')],
  ['monthly', 'Monthly', moment.utc().add(1, 'month')],
  ['yearly', 'Yearly', moment.utc().add(1, 'year')],
];
const freeTypeOptions = [['free', 'Free', moment.utc().add(1, 'month')]];

const paymentMethodOptions = [
  [payMethods.PAYMENT_METHOD_WIRE_TRANSFER.id, payMethods.PAYMENT_METHOD_WIRE_TRANSFER.name],
  [payMethods.PAYMENT_METHOD_FREE.id, payMethods.PAYMENT_METHOD_FREE.name],
];

function getPaymentMethodText(method, cardInfo) {
  return cardInfo
    ? `${getNameById(method, payMethods)}\n${cardInfo.brand}\n${cardInfo.exp_month}/${cardInfo.exp_year}\nXXXX-XXXX-XXXX-${cardInfo.last4}`
    : getNameById(method, payMethods);
}

const ProManagementPage = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();

  const [focus, setFocus] = useState(null);
  const [validationState, setValidationState] = useState({});
  const [editPlanData, setEditPlanData] = useState(null);
  const [modals, setModals] = useState({
    cancelSubModal: false,
    cancelSubRenewalModal: false,
  });
  const {
    proPlan, paymentsHistory, isFetching, isProcessing,
  } = useSelector(getProManagementStore);
  const { user } = useSelector(getUserStore);
  const { errors } = useSelector(getErrorsStore);

  useEffect(() => {
    batch(() => {
      dispatch(fetchPaymentHistory(userId));
      dispatch(fetchProSub(userId));
    });
  }, [dispatch, userId]);

  const addManualSubscription = () => {
    const expirationDateValidatorErr = validators.expiration_date(editPlanData.expiration_date);
    if (expirationDateValidatorErr === null) {
      dispatch(manualSub(userId, fillEmptyPlanData(editPlanData)));
      setEditPlanData(null);
    } else {
      setValidationState((prev) => ({
        ...prev,
        expiration_date: expirationDateValidatorErr,
      }));
    }
  };

  const fillEmptyPlanData = (planData) => {
    if (planData.payment_method === payMethods.PAYMENT_METHOD_FREE.id) {
      const paymentType = match(planData.expiration_date)
        .on((x) => moment.utc(x).isSameOrAfter(paymentTypeOptions[3][2]), () => paymentTypeOptions[3][0])
        .on((x) => moment.utc(x).isSameOrAfter(paymentTypeOptions[2][2]), () => paymentTypeOptions[2][0])
        .on((x) => moment.utc(x).isSameOrAfter(paymentTypeOptions[1][2]), () => paymentTypeOptions[1][0])
        .otherwise(() => paymentTypeOptions[0][0]);
      return {
        ...planData,
        payment_type: paymentType,
        invoice_email: planData.invoice_email || supportEmail,
        price: planData.price || 0,
      };
    }
    return planData;
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;

    if (name === 'payment_method') {
      if (value === 'free') {
        onExpirationChange('');
      } else {
        setEditPlanData((prev) => ({
          ...prev,
          payment_type: paymentTypeOptions[3][0],
          expiration_date: paymentTypeOptions[3][2],
        }));
      }
    }
    if (name === 'payment_type') {
      setEditPlanData((prev) => ({
        ...prev,
        expiration_date: paymentTypeOptions.find((v) => v[0] === value)[2],
      }));
    }
    setEditPlanData((prev) => ({ ...prev, [name]: value }));

    if (name === 'invoice_email') {
      setValidationState((prev) => ({
        ...prev,
        invoice_email: validators.invoice_email(value),
      }));
    }
    if (name === 'price') {
      setValidationState((prev) => ({
        ...prev,
        price: validators.price(value),
      }));
    }
  };

  const onExpirationChange = (date) => {
    setEditPlanData((prev) => ({ ...prev, expiration_date: date }));
    setValidationState((prev) => ({
      ...prev,
      expiration_date: validators.expiration_date(date),
    }));
  };

  const setupManuallyHandler = () => {
    setEditPlanData((prev) => ({
      ...prev,
      payment_type: paymentTypeOptions[3][0],
      payment_method: paymentMethodOptions[0][0],
      currency: supportedCurrencies.USD,
      price: '',
      invoice_email: '',
      expiration_date: paymentTypeOptions[3][2],
    }));
  };

  const onCancelSubscription = () => {
    dispatch(cancelSub(userId));
    setModals(() => setModals({ ...modals, cancelSubModal: false }));
  };

  const onStopSubscription = () => {
    dispatch(stopSub(userId));
    setModals(() => setModals({ ...modals, cancelSubRenewalModal: false }));
  };

  if (isFetching && (errors === null || errors.length === 0)) return <Loader />;

  let form = null;
  if (!proPlan && !editPlanData) {
    form = (
      <>
        <p>No information</p>
        <LinkButton variant="link" onClick={setupManuallyHandler}>
          <FontAwesomeIcon icon="plus-circle" />
          &nbsp;
          Setup a Pro Plan Manually
        </LinkButton>
      </>
    );
  } else if (editPlanData) {
    form = (
      <Fade in appear>
        <div>
          <p>{`To create a Pro plan for ${user?.company_name ?? user?.contact_name}, please update the following fields:`}</p>
          <UniversalControl
            value={editPlanData.payment_type}
            as="select"
            name="payment_type"
            labelText="Payment type:"
            optionsForSelect={editPlanData.payment_method === 'free' ? freeTypeOptions : paymentTypeOptions}
            disabled={editPlanData.payment_method === 'free'}
            onChange={onChangeHandler}
            labelColumnWidth={2}
            controlColumnWidth={3}
          />
          <UniversalControl
            value={editPlanData.payment_method}
            name="payment_method"
            as="select"
            labelText="Payment method:"
            optionsForSelect={paymentMethodOptions}
            onChange={onChangeHandler}
            labelColumnWidth={2}
            controlColumnWidth={3}
          />
          <UniversalControl
            value={editPlanData.price}
            name="price"
            labelText="Price in USD:"
            onChange={onChangeHandler}
            labelColumnWidth={2}
            controlColumnWidth={3}
            {...validationState.price}
          />
          <UniversalControl
            value={editPlanData.invoice_email}
            type="email"
            name="invoice_email"
            labelText="Invoice recipient:"
            onChange={onChangeHandler}
            labelColumnWidth={2}
            controlColumnWidth={3}
            {...validationState.invoice_email}
          />
          <Form.Group as={Row} controlId="formPlaintextEmail">
            <Form.Label column sm="2">
              Plan expiration:
            </Form.Label>
            <Col sm="3">
              <DateRangePickerWrapper>
                <SingleDatePicker
                  date={editPlanData.expiration_date || editPlanData.next_billing_date}
                  onDateChange={onExpirationChange}
                  focused={focus}
                  displayFormat="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  onFocusChange={({ focused }) => setFocus(focused)}
                  isOutsideRange={(day) => isInclusivelyBeforeDay(day, moment())}
                  id="expiration_date"
                  numberOfMonths={1}
                  showDefaultInputIcon
                  small
                />
              </DateRangePickerWrapper>
            </Col>
          </Form.Group>

          <FormActionButtons
            confirmButtonText="Create Pro"
            cancelButtonText="Cancel"
            saveProps={{
              disabled: Object.values(validationState).some((v) => !!v)
                || (editPlanData.payment_method !== payMethods.PAYMENT_METHOD_FREE.id && Object.values(editPlanData).some((v) => !v)),
            }}
            saveHandler={addManualSubscription}
            cancelHandler={() => setEditPlanData(false)}
          />
        </div>
      </Fade>
    );
  } else {
    form = (
      <>
        <UniversalControl
          value={proPlan.payment_type}
          as="select"
          labelText="Payment type:"
          name="payment_type"
          optionsForSelect={paymentTypeOptions}
          labelColumnWidth={2}
          controlColumnWidth={3}
          disabled
          plaintext
        />
        <UniversalControl
          value={getPaymentMethodText(proPlan.payment_method, proPlan.card_info)}
          as="textarea"
          rows="4"
          name="payment_method"
          labelText="Payment method:"
          labelColumnWidth={2}
          controlColumnWidth={3}
          readOnly
          plaintext
        />
        <UniversalControl
          value={`${proPlan?.price?.amount} ${proPlan?.price?.currency}`}
          labelText="Price:"
          name="price"
          labelColumnWidth={2}
          controlColumnWidth={3}
          readOnly
          plaintext
        />
        <UniversalControl
          value={proPlan.next_billing_date ? moment.utc(proPlan.next_billing_date).format('MMM D, YYYY') : '-'}
          labelText="Next billing:"
          name="next_billing_date"
          labelColumnWidth={2}
          controlColumnWidth={3}
          readOnly
          plaintext
        />
        <UniversalControl
          value={proPlan.customer_data?.email}
          labelText="Invoice recipient:"
          name="email"
          labelColumnWidth={2}
          controlColumnWidth={3}
          readOnly
          plaintext
        />

        {(proPlan.expiration_date || (proPlan.stopped_at === null ? null : proPlan.next_billing_date)) && (
          <UniversalControl
            value={moment.utc(proPlan.expiration_date || proPlan.next_billing_date).format('MMM D, YYYY')}
            labelText="Plan expiration date:"
            name="expiration_date"
            labelColumnWidth={2}
            controlColumnWidth={3}
            readOnly
            plaintext
          />
        )}

        {(proPlan.stopped_at
          || proPlan?.status === proSubscriptionStatus.STATUS_SUSPENDED || proPlan?.status === proSubscriptionStatus.STATUS_CANCELED) && (
          <UniversalControl
            value={moment.utc(proPlan.stopped_at || proPlan.canceled_at).format('MMM D, YYYY')}
            labelText="Pro Plan canceled on:"
            name="canceled_on"
            onChange={onChangeHandler}
            labelColumnWidth={2}
            controlColumnWidth={3}
            readOnly
            plaintext
          />
        )}

        {proPlan?.status === proSubscriptionStatus.STATUS_CANCELED || proPlan?.status === proSubscriptionStatus.STATUS_SUSPENDED
          ? (
            <LinkButton variant="link" onClick={setupManuallyHandler}>
              <FontAwesomeIcon icon="plus-circle" />
              &nbsp;
              Setup a Pro Plan Manually
            </LinkButton>
          )
          : (
            <>
              <LinkButton variant="link" onClick={() => setModals({ ...modals, cancelSubModal: true })}>
                <FontAwesomeIcon icon="times-circle" />
                &nbsp;
                Cancel Pro Subscription Now
              </LinkButton>
              {proPlan.payment_method === 'card' && proPlan.stopped_at === null && (
                <LinkButton variant="link" onClick={() => setModals({ ...modals, cancelSubRenewalModal: true })}>
                  <FontAwesomeIcon icon="times-circle" />
                  &nbsp;
                  Cancel Pro Subscription Auto-renewal
                </LinkButton>
              )}
            </>
          )}
      </>
    );
  }

  return (
    <>
      <FormTitleSection title="Relaunch Subscription Info" showEditButton={false} />

      <TabContainer>
        <PlanTitle>
          Current Subscription type:
          <PlanInfo>
            {
              (proPlan?.relaunch_subscription_key || '—').toUpperCase()
            }
          </PlanInfo>
        </PlanTitle>
        <PlanTitle>
          Cost (
          {
            proPlan?.price.currency
          }
          ):
          <PlanInfo>
            {
              proPlan?.price.amount
            }
          </PlanInfo>
        </PlanTitle>
        <PlanTitle>
          Users:
          <PlanInfo>
            {
              proPlan?.users ? proPlan.users : '—'
            }
          </PlanInfo>
        </PlanTitle>
        <PlanTitle>
          Purchase date:
          <PlanInfo>
            {
              moment.utc(proPlan?.updated_at).format('MMM D, YYYY')
            }
          </PlanInfo>
        </PlanTitle>
        <PlanTitle>
          Expiration date:
          <PlanInfo>
            {
              moment.utc(proPlan?.next_billing_date).format('MMM D, YYYY')
            }
          </PlanInfo>
        </PlanTitle>
        <PlanTitle>
          Notes:
          <PlanInfo>
            {
              proPlan?.notes
            }
          </PlanInfo>
        </PlanTitle>
      </TabContainer>

      <FormTitleSection title="Pro Plan Information" showEditButton={false} />
      <TabContainer>
        <PlanTitle>
          {
            !proPlan?.status
            || proPlan.status === proSubscriptionStatus.STATUS_SUSPENDED
            || proPlan.status === proSubscriptionStatus.STATUS_CANCELED
              ? 'Current Plan type: Basic'
              : 'Current Plan type: Pro'
          }
          {(proPlan?.status === proSubscriptionStatus.STATUS_DEBT || proPlan?.status === proSubscriptionStatus.STATUS_SUSPENDED) && (
            <InDebtIcon>In Debt</InDebtIcon>
          )}
        </PlanTitle>

        {form}

        <br />
        <FormSubTitle title="Billing History" showEditButton={false} />
        <Row>
          <Col sm={6}>
            <BillingTable data={paymentsHistory ?? []} />
          </Col>
        </Row>
      </TabContainer>

      <ConfirmationModal
        show={modals?.cancelSubModal}
        confirm={onCancelSubscription}
        onHide={() => setModals({ ...modals, cancelSubModal: false })}
        confirmBtnText="Yes, cancel Pro"
        cancelBtnText="No, don't cancel Pro"
      >
        <h4>Cancel Pro Subscription Now</h4>
        <br />
        <p>{`Are you sure you want to cancel ${user?.company_name ?? user?.contact_name}'s Pro subscription now?`}</p>
      </ConfirmationModal>

      <ConfirmationModal
        show={modals?.cancelSubRenewalModal}
        confirm={onStopSubscription}
        onHide={() => setModals({ ...modals, cancelSubRenewalModal: false })}
        confirmBtnText="Yes, cancel Auto-renewal"
        cancelBtnText="No, don't cancel Auto-renewal"
        size="lg"
      >
        <h4>Cancel Pro Subscription Auto-renewal</h4>
        <br />
        <p>{`Are you sure you want to cancel ${user?.company_name ?? user?.contact_name}'s Pro subscription auto-renewal?`}</p>
      </ConfirmationModal>
      <ModalLoader show={isProcessing} />
    </>
  );
};

export default ProManagementPage;
